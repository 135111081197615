.step-head {
  margin-bottom: 60px;

  div {
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 38px;
  }
}