.step-2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1px 0;

  &__main {
    margin: 20px 0;
    background: #ffffff;
    border-radius: 6px;
    padding: 30px 220px;
    box-sizing: border-box;
    min-height: 776px;
  }

  &__des {
    text-align: center;
    width: 581px;
    padding: 50px 30px;
    background: #e6f3fd;
    border-radius: 6px;
    border: 1px solid #2096fa;
    margin: 0 auto 12px;

    .user,
    .term,
    .group,
    .price {
      margin-bottom: 17px;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        width: 50%;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .span {
        font-size: 18px;
        color: #333333;
        padding-right: 10px;
        display: inline-block;
        width: 110px;
        text-align: right;
      }

      .val {
        text-align: left;
      }
    }

    .price {
      margin-bottom: 0;
    }
  }

  &__tip {
    text-align: center;
    display: flex;
    align-items: center;
    width: 581px;
    margin: 0 auto 50px;
    justify-content: center;

    .des {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }

  &__pay {
    width: 581px;
    margin: 0 auto;

    .code-wrap {
      width: 200px;
      margin: 0 auto;

      .code-head {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;

        .icon {
          display: block;
          width: 32px;
          height: 28px;
          margin-right: 14px;
        }

        .title {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }

      .code-body {
        width: 200px;
        height: 200px;
        background: #ffffff;
        border: 1px solid #4bb417;
        padding: 22px;
        position: relative;
        margin-bottom: 15px;
        box-sizing: border-box;

        .code {
          width: 156px;
          height: 156px;
          display: block;

          &.overdue {
            opacity: 0.5;
          }
        }

        .payed {
          top: 50%;
          left: 50%;
          width: 79px;
          height: 79px;
          display: block;
          position: absolute;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .count-down {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      padding: 0 8px;

      span {
        color: #2096fa;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &__main {
      padding: 30px 16px;
      border-radius: 0;
      margin-top: 12px;
      padding: 16px;
      min-height: auto;
    }

    &__des {
      width: 100%;
      padding: 15px;

      .user,
      .term,
      .group,
      .price {
        justify-content: flex-start;
        p,
        .span {
          font-size: 16px;
        }

        .val {
          flex-shrink: 0;
        }
        
        p {
          width: auto;
        }

        .span {
          width: auto;
        }
      }
    }

    &__tip {
      width: 100%;
      text-align: left;
      line-height: 16px;
    }

    &__pay {
      width: 100%;

      .code-wrap {
        margin-bottom: 20px;
      }
    }
  }
}
