.click-img {
  &__big {
    display: block;
    max-width: 1000px;
    width: auto;
    height: 100%;
    max-height: 600px;
    object-fit: cover;
  }

  &__small {
    width: 80px;
    height: 80px;
    border-radius: 4px;
  }

  &__desc {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-top: 10px;
  }

  .el-dialog--small {
    width: auto;
  }

  .el-dialog__header {
    position: absolute;
    right: -60px;
    top: -14px;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__headerbtn {
    font-size: 20px;
  }

  .v-modal {
    opacity: 0.6;
  }
}
