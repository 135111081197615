.float {
  position: fixed;
  top: 660px;
  right: 50px;

  &__group {
    &--item {
      margin-bottom: 22px;
      background: #ffffff;
      border-radius: 50%;
      box-shadow: 0px 0px 8px 0px rgba(209, 209, 209, 0.5);

      .code {
        width: 116px;
        height: 116px;
        margin: 0 auto;
      }

      .desc {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-top: 14px;
        text-align: center;
      }

      .icon {
        border-radius: 50%;
      }

      // contact
      .contact {
        padding: 20px;
      }

      .contact-item {
        &:last-child {
          .val {
            margin-bottom: 0;
          }
        }
      }

      .type {
        display: flex;
        margin-bottom: 10px;
        align-items: center;

        .icon {
          margin-right: 8px;
          width: 16px;
          height: 16px;
        }

        .key {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 25px;
        }
      }

      .val {
        padding-left: 24px;
        margin-bottom: 20px;
        font-size: 18px;
        font-family: ArialMT;
        color: #666666;
        line-height: 21px;
      }
    }
  }

  // modify the element react ui default style
  .el-popover {
    border-radius: 6px;
    box-shadow: 0px 0px 8px 0px rgba(209, 209, 209, 0.5);
  }
}
