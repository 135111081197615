.modal {
  &.fill {
    .el-input {
      text-align: left;
    }

    .el-input__inner {
      width: 100%;
    }

    .content {
      padding-bottom: 30px;
      max-height: 75vh;
      overflow: auto;

      .el-form {
        margin-bottom: 22px;

        &:last-of-type {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }

  .date-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date-item {
      width: 50%;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }

    .el-date-editor {
      margin-right: 15px;
      width: 100%;
    }
  }

  .add-btn {
    margin-left: 120px;

    .el-button {
      width: 100%;
      border: 1px solid #009aff;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #009aff;
    }
  }
}
