.header {
  width: 100%;
  height: 72px;
  line-height: 72px;
  margin: 0 auto;
  box-shadow: 0px 2px 8px 0px rgba(197, 197, 197, 0.5);

  &__wrap {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--item {
      display: flex;
      align-items: center;
    }
  }

  &__status {
    display: flex;
    align-items: center;

    .headImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;
      margin-left: 38px;
    }

    .status {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595959;
      line-height: 22px;
      cursor: pointer;
    }
  }

  &__link {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #595959;

    &.pc {
      display: inline-block;
    }

    &.mobile {
      display: none;
    }

    .logo {
      width: 130px;
      height: auto;
      display: block;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 1200px) {
    height: 60px;

    &__wrap {
      padding: 0 15px;
    }

    &__status {
      display: none;
    }

    &__link {
      line-height: 60px;
      font-size: 14px;
      // color: ;
      &.pc {
        display: none;
      }

      &.mobile {
        display: inline-block;
      }

      .logo {
        width: 100px;
      }
    }
  }
}
.el-icon-arrow-right::before {
  font-size: 14px !important;
}
