.upload {
  .el-upload {
    width: 80px;
    height: 80px;
    background: #f7f8fa;
    border-radius: 4px;
    position: relative;
    cursor: pointer;

    img {
      width: 80px;
      height: 80px;
    }

    &__text {
      text-align: center;
      padding-right: 30px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }

  .avatar-uploader-icon {
    top: 50%;
    left: 50%;
    width: 22px;
    height: 19px;
    position: absolute;
    transform: translate(-50%, -50%);
    background: url("../../assets/photo.svg") center center;

    &::before,
    &::after {
      content: "";
    }
  }
}
