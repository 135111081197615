.privacy {
  width: 100%;
  max-width: 1200px;
  border-radius: 6px;
  margin: 0 auto;
  padding-top: 1px;

  &__main {
    margin-top: 20px;
    padding: 30px;
    background: #ffffff;
    min-height: calc(100vh - 182px);

    &--title {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
      margin-bottom: 24px;
    }

    &--box {
      margin-bottom: 36px;
    }

    &--sub-title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 24px;
    }

    &--desc {
      font-size: 16px;
      color: #666666;
      margin-bottom: 20px;
      line-height: 25px;

      .spe {
        color: #2096FA;
      }
    }
  }

  @media screen and (min-width: 1200px){
    &__main {

    }
  }
}
