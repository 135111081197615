.step-3 {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 1px 0;

  &__main {
    margin: 20px 0;
    background: #ffffff;
    border-radius: 6px;
    padding: 30px 220px;
    box-sizing: border-box;
    min-height: 776px;
  }

  &__state {
    .img {
      margin: 0 auto 70px;
      display: block;
      width: 72px;
      height: 72px;
      margin-bottom: 23px;
    }

    .title {
      margin-bottom: 10px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .l-des {
      width: 315px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      margin: 0 auto 50px;

      span {
        color: #2096FA;
      }
    }

    .logo {
      display: block;
      width: 166px;
      height: 166px;
      border-radius: 50%;
      margin: 0 auto 40px;
    }
  }

  &__back {
    .toHome {
      padding: 8px 10px;
      background: #009AFF;
      border-radius: 2px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      border: none;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1200px) {
    &__main {
      padding: 30px 16px;
      border-radius: 0;
      margin-top: 12px;
      padding: 16px;
      min-height: auto;
    }

    &__back {
      .toHome {
        margin-bottom: 20px;
      }
    } 
  }
}