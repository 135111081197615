.footer {
  background-color: #222222;
  padding: 24px 0;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;

  .copyright,
  a {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.8;
  }

  a {
    padding: 0 4px;
  }

  @media screen and (max-width: 1200px) {
    padding:  12px;

    .copyright,
    a {
      line-height: 16px;
      padding: 0 15px;
    }
  }
}
