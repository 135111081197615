.form {
  width: 100%;
  padding-top: 1px;
  margin: 0 auto;
  padding-bottom: 1px;
  background: #f2f3f5;
  box-sizing: border-box;
  min-height: calc(100vh - 135px);

  &__main {
    width: 100%;
    margin: 20px auto;
    border-radius: 6px;
    box-sizing: border-box;
    max-width: 1099px;
    background: #ffffff;
    box-sizing: border-box;
    min-height: calc(100vh - 135px);
    padding: 40px 30px;

    .application {
      margin-top: 38px;
      padding-right: 140px;
      text-align: right;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 12px;
    }

    .time {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      text-align: right;
    }

    .val {
      display: inline-block;
      margin-right: 50px;
    }

    .print-box {
      width: 100%;
      text-align: center;
      margin-top: 40px;
    }

    .print {
      display: inline-block;
      margin: 0 auto;
      border: none;
      cursor: pointer;
      background: #009aff;
      border-radius: 6px;
      padding: 8px 17px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 25px;
    }

    .value {
      min-height: 200px;
      display: flex;
      align-items: flex-end;
      width: 100%;

      .box {
        width: 100%;
      }
    }

    .time {
      text-align: right;
    }

    .people-title {
      min-height: 200px;
    }
  }

  &__table {
    width: 100%;
    border-spacing: 0;

    td {
      height: 38px;
      box-sizing: border-box;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    .fontStyl {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }

  &__table-head {
    tr {
      width: 100%;

      td {
        box-sizing: border-box;
        padding: 10px 15px;
        text-align: center;
      }

      .title {
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 37px;
      }

      .sub-title {
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }
    }
  }

  &__table-body {
    width: 100%;

    tr {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #EAEFF0;

      td {
        width: 100%;
        padding: 10px 15px;
        border: 1px solid #EAEFF0;
      }
    }
  }

  &__content {
    .img-box {
      position: relative;
      width: 100%;
    }
    .headImg {
      width: 100px;
      height: 140px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
