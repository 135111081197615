.profile {
  width: 100%;
  padding-top: 1px;
  margin: 0 auto;
  padding-bottom: 1px;
  min-height: calc(100vh - 132px);
  background: #f2f3f5;

  &__head {
    width: 100%;
    height: 174px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("./assets/head.jpg");
    background-color: rgba($color: #000000, $alpha: 0.1);
    padding: 40px 0;

    &--wrap {
      max-width: 1200px;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
    }

    &--item {
      display: flex;
      align-items: center;
      position: relative;
    }

    &--img-box {
      width: 92px;
      height: 92px;
      border-radius: 50%;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($color: #ffffff, $alpha: 0.3);
    }

    &--img {
      display: block;
      width: 82px;
      height: 82px;
      object-fit: cover;
      border-radius: 50%;
    }

    &--desc {
      margin-left: 30px;

      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
        margin-bottom: 10px;
      }

      .desc {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
    }

    &--fucn-box {
      display: flex;

      .line {
        display: inline-block;
        width: 1px;
        height: 32px;
        background-color: #ffffff;
        position: absolute;
        opacity: 0.5;
        bottom: -4px;
      }

      &:last-child {
        .line {
          display: none;
        }
      }
    }

    &--fucn {
      padding: 0 10px;
      text-align: center;
      cursor: pointer;

      &:hover {
        .icon {
          display: none;

          &.hover {
            display: block;
          }
        }
      }

      .desc {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        margin-bottom: 16px;
      }

      .icon {
        margin: 10px auto 0;

        &.hover {
          display: none;
        }
      }
    }
  }

  &__main {
    margin: 0 auto;
    border-radius: 6px;
    box-sizing: border-box;
    max-width: 1200px;
    padding: 20px 0;
  }

  &__section {
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 20px;

    &--child {
      padding: 20px 0;
      margin: 0 40px;
      border-bottom: 1px dashed #d8d8d8;

      &:last-child {
        border-bottom: 1px solid transparent;
      }

      .title,
      .l-desc {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        margin-bottom: 2px;
      }

      .l-desc {
        padding: 18px 0;
        font-weight: 500;
      }
    }
  }

  &__title {
    padding: 18px 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #eeeeee;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
  }

  &__group {
    padding: 18px 40px 0;
    display: flex;
    flex-wrap: wrap;

    &--item {
      width: 50%;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 20px;

      display: flex;

      .type,
      .val {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        width: 105px;
      }

      .val {
        width: auto;
        color: #666666;
      }
    }
  }

  &__list {
    display: flex;
    padding: 18px 0;

    &--item {
      width: 80px;
      margin-right: 30px;

      .img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 4px;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .desc {
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
    }
  }
}

.exprence {
  .profile__group {
    padding-left: 0;
    padding-right: 0;
  }
}
