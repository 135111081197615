.sign-up {
  width: 100%;
  background-color: #fff;
  min-height: calc(100vh - 132px);

  &__banner {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 60px;
  }

  &__wrap {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 514px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__head {
    margin-bottom: 50px;
    text-align: center;

    .title {
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 22px;
      width: 270px;
      line-height: 50px;
      text-align: left;
    }

    .des {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      text-align: left;
    }
  }

  &__func {
    width: 334px;
    height: 428px;
    padding: 24px;
    background-color: #fff;
    border-radius: 6px;
    position: relative;

    .title {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      text-align: center;
      margin-bottom: 32px;
    }

    .qr-code {
      display: flex;
      align-items: flex-start;
    }

    .qr-type {
      width: 83px;
      height: 20px;
      color: #009aff;
      position: relative;
      display: inline-block;
      margin-right: 14px;
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      padding-right: 3px;

      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .code-wrap {
      position: relative;
      cursor: pointer;

      .code {
        width: 37px;
        height: 37px;
      }

      &::before {
        content: "";
        width: 0;
        height: 0;
        display: block;
        border-left: 37px solid #fff;
        border-bottom: 0 solid #fff;
        border-top: 37px solid transparent;
        position: absolute;
        z-index: 1;
      }
    }

    &--fill,
    &--scan {
      height: 100%;
      position: relative;
    }

    &--form {
      .el-input__inner {
        height: 42px;
        padding: 10px 12px;
        border-radius: 6px;
      }

      .verification {
        display: flex;
        margin-bottom: 10px;

        .el-input {
          margin-right: 10px;
          width: calc(100% - 134px);
        }

        .el-button {
          &--primary {
            width: 124px;
            background: #d7eeff;
            border-radius: 6px;
            border: 1px solid #009aff;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #009aff;

            &.is-disabled {
              color: #9a9a9a;
              background: #f2f2f2;
              border: 1px solid #d8d8d8;
            }
          }
        }
      }

      .full-width {
        .el-button {
          height: 45px;
          width: 100%;
          background: #009aff;
          border-radius: 6px;

          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;

          &.is-disabled {
            color: #9a9a9a;
            background: #f2f2f2;
            border: 1px solid #d8d8d8;
          }
        }
      }

      .clause {
        .el-checkbox {
          &__input {
            height: 13px;

            &.is-checked {
              .el-checkbox__inner {
                &::after {
                  left: 3px;
                  top: -1px;
                }
              }
            }
          }

          &__inner {
            width: 13px;
            height: 13px;
            border-radius: 0;

            &::after {
              left: 3px;
              top: -1px;
            }
          }
        }

        .el-form-item__content,
        .txt {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9a9a9a;
          cursor: pointer;

          a {
            color: #009aff;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    &--scal {
      height: 100%;

      .code {
        display: block;
        width: 200px;
        height: auto;
        object-fit: cover;
        margin: 0 auto 36px;
      }

      .text {
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;

        .icon {
          display: inline-block;
          width: 15px;
          height: 15px;
          margin-right: 10px;
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
        }

        span {
          color: #009aff;
          padding: 0 5px;
        }
      }
    }
  }

  &__toggle {
    width: 100%;
    position: absolute;
    bottom: 0;

    &--line {
      width: 100%;
      height: 1px;
      background-color: #d8d8d8;
      position: relative;
      margin-bottom: 22px;

      .des {
        position: absolute;
        min-width: 68px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background-color: white;
        padding: 6px;
      }
    }

    &--wechat {
      margin: 0 auto;
      cursor: pointer;
    }
  }

  &__body {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    align-self: center;
    padding-bottom: 80px;
    justify-content: space-between;

    &--item {
      width: 558px;
    }

    &--title {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 22px;
      text-align: center;
    }

    &--des {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 20px;
      text-align: center;
    }

    &--img-wrap {
      border-radius: 6px;
    }

    &--img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__company {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 90px;

    &--title {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 22px;
      text-align: center;
    }

    &--des {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 40px;
      text-align: center;
    }

    &--img,
    &--m-img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    &--m-img {
      display: none;
    }
  }

  &__slogan {
    width: 100%;
    height: 294px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &--wrap {
      max-width: 1200px;
      margin: 0 auto;
      text-align: center;
    }

    &--title {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 50px;
    }

    .el-button {
      &--primary {
        width: 160px;
        height: 50px;
        background: #ffffff;
        border-radius: 6px;
        margin: 0 auto;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #009aff;

        &:hover,
        &:active {
          background-color: #ffffff;
          background: #ffffff;
          color: #009aff;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    // padding: 0 15px;\
    &__wrap {
      display: block;
      padding: 20px;
      height: auto;
    }

    &__head {
      margin-bottom: 0;

      .title {
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        width: 100%;
        text-align: center;
        margin-bottom: 8px;
      }

      .des {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-bottom: 20px;
        text-align: center;
      }
    }

    &__func {
      width: 100%;
      padding: 20px;
      height: auto;

      &--scan {
        padding-bottom: 10px;
      }

      &--scal {
        height: auto;
      }
    }

    &__toggle {
      &--tip {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        text-align: center;
      }
    }

    &__body {
      display: block;
      padding: 0 20px;

      &--item {
        width: 100%;
        margin-bottom: 40px;
      }

      &--title {
        font-size: 22px;
        font-weight: 500;
      }

      &--des {
        line-height: 22px;
      }
    }

    &__company {
      width: 100%;
      padding: 0 20px;

      &--title {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 20px;
      }

      &--des {
        line-height: 22px;
        margin-bottom: 18px;
        padding: 0 10px;
      }

      &--img {
        display: none;
      }

      &--m-img {
        display: block;
      }
    }

    &__slogan {
      padding: 0 20px;

      &--title {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}
