.modal {
	&.fill {
		.el-input {
			text-align: left;
		}

		.el-input__inner {
			width: 100%;
		}

		.header {
			box-shadow: none;
		}

		.content {
			padding: 30px 80px;
			max-height: 75vh;
			overflow: auto;
      &-list{
        margin-top: 28px;
      }
			&__text {
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #333333;
				line-height: 20px;
			}
			&__first, &__second {
				display: flex;
				// justify-content: space-between;
        flex-wrap: wrap;
			}
      &__out__box{
        width: 25%;
      }
			&__box, &__box__second {
        margin-top: 17px;
				width: 96px;
				height: 28px;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #666666;
				background-color: #eee;
				border-radius: 2px;
				// border: 1px solid #d8d8d8;
        cursor: pointer;
			}
			&__box__second{
				color: #666666;
				background-color: transparent;
				border: 1px solid #d8d8d8;
			}
      .first-checked{
        color: #FFFFFF;
        background: #009AFF;
        border: 1px solid #009AFF;
      }
      
      .second-checked{
        color: #009AFF;
        border: 1px solid #009AFF;
      }
		}
	}
}

.popup-content {
	@media screen and (max-width: 1200px) {
		width: 92%;

		.modal {
			&.fill {
				.content {
					padding: 30px 15px;
				}

				.content__out__box {
					box-sizing: border-box;
					width: calc(33.33% - 6px);
					margin: 0 3px;
				}

				.content__box {
					width: 100%;
				}
			}
		}
	}
}
