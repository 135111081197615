.search {
  // margin-bottom: 22px;

  &::before,
  &::after {
    display: table;
    content: "";
  }

  &::after {
    clear: both;
  }

  &__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    width: 110px;
    text-align: left;
    line-height: 1;
    padding: 11px 12px 11px 0;
    box-sizing: border-box;
    vertical-align: middle;
    float: left;

    &::before {
      content: "*";
      color: #ff4949;
      margin-right: 4px;
    }
  }

  &__content {
    // margin-left: 110px;
    line-height: 36px;
    position: relative;
    font-size: 14px;

    &::before,
    &::after {
      content: "";
      display: table;
    }

    &:after {
      clear: both;
    }

    .el-input {
      &__icon {
        .icon {
          top: 50%;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
        }
      }
    }

    .search-autoComplete-suggestion {
      display: none;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.5);
      filter: blur(0px);
      margin: 5px 0;
      padding-top: 6px;
      padding-bottom: 14px;

      z-index: 1;
      width: 100%;
      position: absolute;
      top: 36px;
      left: 0px;
      will-change: top, left;

      &.showView {
        display: block;
      }

      .notice {
        line-height: normal;
        padding: 8px 20px;

        list-style: none;
        margin: 0;
        cursor: pointer;
        color: #48576a;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.center {
          text-align: center;
        }
      }
    }

    // 错误提示
    .search-input__error {
      color: #ff4949;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 100%;
      left: 0;
      display: none;
    }

    .el-scrollbar {
      overflow: hidden;
      position: relative;

      &__view {
        position: relative;
        margin: 0;
        padding: 0;
        max-height: 56vh;
        overflow: auto;

        li {
          line-height: normal;
          padding: 8px 20px;

          list-style: none;
          margin: 0;
          cursor: pointer;
          color: #48576a;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            background-color: #e4e8f1;
          }
        }
      }
    }

    .add-bar {
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      display: flex;
      justify-content: center;

      .ts {
        .button {
          color: #2096fa;
          padding-left: 3px;
          cursor: pointer;
          border: none;
          background: transparent;
        }
      }
    }

    .el-autocomplete-suggestion__wrap {
      margin-right: -17px;
      margin-bottom: -17px;

      overflow: scroll;

      max-height: 280px;
      overflow: auto;
      background-color: #fff;
      padding: 6px 0;
      border-radius: 2px;
      box-sizing: border-box;
    }
  }
}