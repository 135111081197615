.modal {
  &.fill-popup {
    .el-input {
      text-align: left;
    }

    .el-input__inner {
      width: 100%;
    }

    .header {
      box-shadow: none;
    }

    .content {
      padding: 0 0 30px;
      max-height: 75vh;
      overflow: auto;

      .el-form {
        margin-bottom: 22px;

        .el-form-item {
          padding: 0 60px;

          @media screen and (max-width: 1200px) {
            padding: 0 28px;
          }
        }

        &:last-of-type {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }

  // delete icon style
  .delete-icon {
    text-align: right;
    margin-bottom: 20px;
    display: flex;
    padding: 10px 30px;
    background-color: #f2f3f5;
    justify-content: space-between;

    .sub-title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .el-icon-delete {
      cursor: pointer;
      color: #999999;
    }
  }

  .date-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date-item {
      width: 50%;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }

    .el-date-editor {
      margin-right: 15px;
      width: 100%;
    }
  }

  .add-btn {
    margin-left: 120px;
    padding: 0 60px;

    .el-button {
      width: 100%;
      border: 1px solid #009aff;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #009aff;
    }
  }
}

.msgpop {
  z-index: 1000;
}
