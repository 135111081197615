.modal {
  .header {
    width: 100%;
    border-bottom: 1px solid #d8d8d8;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding: 15px 30px;
    line-height: 25px;
    height: 55px;
  }

  .content {
    width: 100%;
    padding: 30px;
  }

  .el-input {
    width: 100%;
    text-align: center;
  }

  .el-input__inner {
    width: 408px;
    margin: 0 auto;
  }

  .actions {
    width: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      margin-right: 30px;
      width: 100px;
      height: 36px;
      border-radius: 2px;
      text-align: center;
      border: 1px solid #d8d8d8;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      background: #ffffff;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
        background: #009AFF;
        color: #ffffff;
      }

      &.is-disabled {
        color: #bfcbd9;
        cursor: not-allowed;
        background-image: none;
        background-color: #eef1f6;
        border-color: #d1dbe5;
      }
    }
  }

  .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: 30px;
    top: 16px;
    font-size: 38px;
    background: #ffffff;
    color: #999999;
    border: none;
  }
}
