.step-1 {
  width: 100%;
  max-width: 1200px;
  padding-top: 1px;
  margin: 0 auto;
  padding-bottom: 1px;

  &__main {
    margin: 20px 0;
    background: #ffffff;
    border-radius: 6px;
    padding: 30px 220px;
    box-sizing: border-box;

    .custom-item {
      .el-form-item {
        margin-bottom: 12px;
      }
    }

    .user-phone {
      .el-input {
        &.is-disabled {
          .el-input__inner {
            background-color: #ffffff;
            border-color: #ffffff;
            color: #b3b3b3;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
          }
        }
      }
      .el-input__inner {
        border: none;
        padding-left: 0;
      }
    }

    // 证件照
    .photo {
      .el-upload {
        width: 80px;
        height: 80px;
        background: #f7f8fa;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        img {
          display: block;
          width: 80px;
          height: 80px;
        }
      }

      .avatar-uploader-icon {
        top: 50%;
        left: 50%;
        width: 22px;
        height: 19px;
        position: absolute;
        transform: translate(-50%, -50%);
        background: url("./assets/photo.svg") center center;

        &::before,
        &::after {
          content: "";
        }
      }
    }

    // 出生日期选择
    .date-choose {
      .el-date-editor {
        width: 100%;
      }
    }

    // 籍贯
    .linkage {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__item {
        width: 50%;
      }
    }

    // 个人简历
    .upload-resume {
      margin-top: 18px;

      .el-button {
        &--primary {
          border-radius: 2px;
          border: 1px solid #009aff;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #009aff;
          background-color: #ffffff;
        }
      }
    }

    // 底部按钮
    .app__btns {
      display: flex;
      align-items: center;
      justify-content: center;

      .el-button {
        width: 100px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        padding: 8px 0;
        border-radius: 2px;
        line-height: 20px;
      }

      .cancle {
        color: #666666;
      }

      .next {
        color: #ffffff;
        border-color: #009aff;
        background-color: #009aff;
      }
    }

    // 资格证书
    .certificate {
      display: flex;
      align-items: center;

      .el-upload {
        margin-right: 30px;
      }
    }

    // common style
    .el-select {
      width: 100%;
    }

    .el-form {
      .el-form-item__label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .el-radio-group {
        .el-radio {
          margin-right: 15px;
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &__main {
      padding: 30px 16px;
      border-radius: 0;
      margin-top: 12px;
      padding: 16px;
    }

    .linkage {
      flex-wrap: wrap;

      &__item {
        display: block;
        width: 100%;
      }
    }

    .certificate {
      flex-wrap: wrap;
    }

    .btn-box {
      .el-form-item__content {
        margin-left: 0 !important;
        margin-top: 20px;
      }
    }
  }
}

.modal {
  .close,
  .button {
    &:focus-visible {
      outline: none !important;
    }
  }
}
